import React, {useRef} from 'react';
import Grid from '@mui/material/Grid';
import { Controller } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';

export default function FormTinyMCE({name,defaultValue="",label,height=500,rules,xs=12,md=6,control,required,disabled,customButton}) {
  const editorRef = useRef(null);
  return (
    <Grid item xs={xs} md={md} style={{marginBottom:5}}>
      <Controller
          name={name}
          control={control}
          defaultValue={defaultValue} 
          rules={rules}
          render={({ field,fieldState }) =>	{
              const { onChange, ...newField } = field;
              return (
                  <Editor
                      tinymceScriptSrc='/lib/tinymce/tinymce.min.js'
                      licenseKey='gpl'
                  //apiKey='tzv8y8j6htf9o0k4pwerwulyij70qnvox0fkk3mb58epx3o1'
                  onInit={(evt, editor) => editorRef.current = editor}
                  init={{
                      branding: false,
                      height: height,
                      menubar: false,
                      plugins: [
                        'textcolor',
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | fontfamily fontsize | ' +
                        'bold italic underline forecolor backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | listaPlanceholder',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 11pt }',
                    font_size_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
                    setup: function (editor) {
                        editor.ui.registry.addButton('listaPlanceholder', {
                            text: 'Lista campi disponibili',
                            onAction: function (_) {
                                customButton();
                            }
                        });
                    }
                  }}
                  {...newField}
                  error={!!fieldState.error}
                  label={label}
                  required={required} 
                  disabled={disabled}
                  onEditorChange={(newValue) => {
                      onChange(newValue);
                  }}
              />
              )
          }
          

          }/>      
    </Grid>)
  }



import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import api from '../lib/api2';
import useNotification from 'components/useNotification';
import { GridActionsCellItem } from '@mui/x-data-grid';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Grid from '@mui/material/Grid';
import FormTitle from 'components/FormTitle';
import SimpleDataGrid from 'components/SimpleDataGrid';
import { DateTime } from "luxon";
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function PagamentiClienti({idContratto, salvaPagamenti}) {
	const { id } = useParams();
    const [selectedScadenze, setSelectedScadenze]=useState([]);
	const { pushNotification } = useNotification();
	const [pagamentiMetodi, setPagamentiMetodi] = useState([]);

	useEffect(() => {
		api.get('/pagamentiMetodi').then(data=>{
			setPagamentiMetodi(data);
		});
  	}, []);

	const apriContratto = useCallback ( data => {
		const tipo = data.row.codice;
		const idDocumento = data.row.contratto;
		window.open("/contratti/"+tipo+"/"+idDocumento);
	}, []);
	
	const apriDocumento = useCallback ( data => {
		const idDocumento = data.row.idDocumento;
		window.open("/documenti/"+idDocumento);
	}, []);

    const pagamentoDocumento = documento=>{
		const oggi = DateTime.now().toFormat('yyyy-MM-dd');
		const postData = {idDocumento:documento, pagata:1, dataPagamento:oggi, metodoPagamento:"MP08", _action:'e'};
		api.post('/documenti', postData).then(data=>{
			pushNotification("Segnato come pagato.", "success");
		}, error=>{
			pushNotification("Errore", "error");
		});
	}

	const aggiornaMetodoPagamento = (idDocumento, nuovoMetodo) => {
		const postData = { idDocumento: idDocumento, metodoPagamento: nuovoMetodo, _action: 'e' };
		api.post('/documenti', postData).then(data => {
			pushNotification("Metodo di pagamento aggiornato.", "success");
		}, error => {
			pushNotification("Errore nell'aggiornamento del metodo di pagamento", "error");
		});
	};
	

	const colonnePagamentiCliente = [
		{
			field: 'tipoContratto',
			headerName: 'Tipo contratto',
			editable: false,
			width: 200,
			renderCell: (params) => params.value && (
			  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>{params.value}</span>
				<IconButton onClick={() => apriContratto(params)}>
				  <OpenInNewIcon />
				</IconButton>
			  </div>
			),
		},
		{ field: 'data', headerName: 'Data', width: 120, editable: false, valueFormatter: params => params?.value ? DateTime.fromSQL(params?.value).toLocaleString({day:'2-digit', month:'2-digit', year:'numeric'}) : '', },
		{
			field: 'numero',
			headerName: 'Numero',
			editable: false,
			width: 100,
			renderCell: (params) => params.value && (
			  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>{params.value}</span>
				<IconButton onClick={() => apriDocumento(params)}>
				  <OpenInNewIcon />
				</IconButton>
			  </div>
			),
		},
		{ field: 'importoTotale', headerName: 'Importo', width: 120, editable: false, type:'number',
			valueFormatter: params => {
			  return new Intl.NumberFormat('it-IT', {
				style: 'currency',
				currency: 'EUR'
			  }).format(params.value);
			}
		},
		{ field: 'matricolaStrumento', headerName: 'Matricola', width: 120, editable: false, },
		{ field: 'marcaStrumento', headerName: 'Marca', width: 120, editable: false, },
		{ field: 'modelloStrumento', headerName: 'Modello', width: 120, editable: false, },
		{ field: 'pagata', headerName: 'Pagata', width: 80, editable: false, valueFormatter: params => params.value.toString()==='1' ? 'SI' : 'NO' }, //...cellCheckbox01() },
		{ field: 'dataPagamento', headerName: 'Data pagamento', width: 160, editable: true, valueFormatter: params => params?.value ? DateTime.fromSQL(params?.value).toLocaleString({day:'2-digit', month:'2-digit', year:'numeric'}) : '', },
		//{ field: 'descrizioneMetodoPagamento', headerName: 'Metodo pagamento', width: 40, editable: true, flex:1, },
		{
			field: 'metodoPagamento',
			headerName: 'Metodo pagamento',
			width: 200,
			editable: true,
			renderCell: (params) => (
				<Select
					value={params.value || ''}
					onChange={(event) => {
						const nuovoMetodo = event.target.value;
						aggiornaMetodoPagamento(params.row.idDocumento, nuovoMetodo);
					}}
					fullWidth
					sx={{
						'& .MuiOutlinedInput-notchedOutline': {
							border: 'none'
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							border: 'none'
						},
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							border: 'none'
						}
					}}
				>
					{pagamentiMetodi.map((metodo) => (
						<MenuItem key={metodo.codice} value={metodo.codice}>
							{metodo.descrizione}
						</MenuItem>
					))}
				</Select>
			),
		},
		{ field: '_1', headerName: '', width: 35, type:'actions', getActions: params => [
			<GridActionsCellItem label='' icon={<PointOfSaleIcon />} onClick={()=>pagamentoDocumento(params.row.idDocumento)}  disabled={!params.row.idDocumento || params.row.pagata==="1"} />,
		]}
	];

    return 	(			
        <>
            <FormTitle variant="h6">Pagamenti</FormTitle>
            <Grid item xs={12} >
                <SimpleDataGrid
                    idField="idDocumento"
					getUrl={id!=='new' ? "/clienti/"+id+"/pagamenti?sort=dataPagamento&tipoDocumento=TD01" : ""}
                    columns={colonnePagamentiCliente}
                    allowAdd={false}
                    newRowTemplate={{contratto:idContratto, tipoDocumento:'TD01'}}
                    allowDelete={false}
                    upHandleSalva={salvaPagamenti}
                    gridProps={{
                        onSelectionModelChange: selected => setSelectedScadenze(selected),
                        rowSelectionModel:selectedScadenze,
                        disableMultipleRowSelection:true
                    }}
                />
            </Grid>
        </>
    )

} 
import React, { useState, useEffect, useCallback } from 'react';
import api from '../lib/api2';
import ContextCommand from 'components/ContextCommand';
import useNotification from 'components/useNotification';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DateTime } from "luxon";
import IconButton from '@mui/material/IconButton';
import Loader from '../components/Loader';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import moment from 'moment';
import Filtro from './Filtro';
import qsBuilder from 'lib/qsBuilder';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { CircularProgress } from '@mui/material';

export default function Scadenze({setTitle, setContextCommands}) {
	const { pushNotification } = useNotification();
	const [ isLoaderOpen, setLoaderOpen] = useState(false);
	const [ selectedRows, setSelectedRows ] = useState([]);
	const [ rows, setRows ] = useState([]);
	const [ queryString, setQueryString ] = useState('');
	const [ riepilogo, setRiepilogo ] = useState({totale:0,totaleIva:0,nsel:0,nrighe:0,totaleSel:0,totaleIvaSel:0});
	const [dialogOpen, setDialogOpen] = useState(false);

	const columns = [
		{ field: 'idScadenza', headerName: 'ID', width: 80, },
		{ field: 'descrizione', headerName: 'Tipo ctr.', width: 100 },
		{ field: 'contratto', headerName: 'N° ctr.', width: 100 },
		{ field: 'data', headerName: 'Data ctr.', width: 100, valueFormatter: v=>moment(v.value).format('DD/MM/YYYY') },
		//{ field: 'denominazioneCliente', headerName: 'Nominativo', flex:1, minWidth: 250},
		{
			field: 'denominazioneCliente',
			headerName: 'Nominativo',
			flex:1,
			minWidth: 250,
			renderCell: (params) => params.value && (
			  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>{params.value}</span>
				<IconButton onClick={() => apriCliente(params)}>
				  <OpenInNewIcon />
				</IconButton>
			  </div>
			),
		},
		{ field: 'denominazioneStrumento', headerName: 'Strumento', width: 240 },
		
		{ field: 'dataScadenza', headerName: 'Data scadenza', width: 120, type:'date', valueGetter:params=>DateTime.fromSQL(params.value).toLocaleString({day:'2-digit', month:'2-digit', year:'numeric'}) },
		{
			field: 'descrizioneDocumento',
			headerName: 'Documento',
			width: 160,
			renderCell: (params) => params.value && (
			  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>{params.value}</span>
				<IconButton onClick={() => apriDocumento(params)}>
				  <OpenInNewIcon />
				</IconButton>
			  </div>
			),
		},
		{ field: 'pagata', headerName: 'Pagata', width: 80, align: "center",  valueGetter:params=>(params.value === "1") ? "SI" : "NO" },
		{
			field: 'ivaDocumento',
			headerName: 'IVA',
			width: 100,
			type:'number',
			valueFormatter: params => {
			  return new Intl.NumberFormat('it-IT', {
				style: 'currency',
				currency: 'EUR'
			  }).format(params.value);
			}
		},
		{
			field: 'totaleDocumento',
			headerName: 'Importo',
			width: 100,
			type:'number',
			valueFormatter: params => {
			  return new Intl.NumberFormat('it-IT', {
				style: 'currency',
				currency: 'EUR'
			  }).format(params.value);
			}
		},
	]

	useEffect(() => {
		setTitle('Fatture previste');
	}, [setTitle]);

	const caricaScadenze = useCallback ( data => {
		api.get("/contratti/scadenze?"+queryString+"&sort=contrattiScadenze.dataScadenza").then(righe => {
			setRows(righe);
			setLoaderOpen(false);
		  });
	}, [queryString]);

	useEffect(()=>{
		if (queryString) {
			setLoaderOpen(true);
			caricaScadenze();
		}
	}, [queryString, caricaScadenze])

	const apriCliente = useCallback ( data => {
		const idCliente = data.row.idCliente;
		window.open("/clienti/"+idCliente);
	}, []);

	const apriDocumento = useCallback ( data => {
		const idDocumento = data.row.idDocumento;
		window.open("/documenti/"+idDocumento);
	}, []);
	
	const inviaMail = useCallback ( fattureCreate => {
		try {
			for (const documento of fattureCreate) {
				api.post("/documenti/" + documento + "/mail", "");
				pushNotification("Mail inviata", "success");
			}
		} catch (error) {
			pushNotification("Errore nell'invio delle mail", "error");
		}
	}, [pushNotification]);

	const emettiFatture = useCallback ((data, invioMail) => {
		if (data.length===0) {
			alert ("Nessuna scadenza selezionata");
			return;
		}
		if (!window.confirm("Confermare l'emissione " + (invioMail? "e l'invio email di cortesia":'')+ " delle fatture?")) return;
		setDialogOpen(true);
		api.post("/contratti/genera/fatture", data).then(r=>{
			if (invioMail) inviaMail(r);
			pushNotification("Fatture create correttamente", "success");
			setDialogOpen(false);
			caricaScadenze();
		}, error=>{
			pushNotification("Errore nella creazione delle fatture", "error");
			setDialogOpen(false);
		});
	}, [pushNotification, inviaMail, caricaScadenze]);
	
	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="invoice-text-plus" onClick={ data=>emettiFatture(selectedRows,false) }>Emetti fatture</ContextCommand>,
			<ContextCommand key="2" icon="invoice-text-send" onClick={ data=>emettiFatture(selectedRows,true) }>Emetti fatture + mail cortesia</ContextCommand>,
		]);
  	}, [setContextCommands, emettiFatture, selectedRows] );

	const filtra = useCallback ( filtro => {
		setQueryString(qsBuilder(filtro));
	}, []);

	useEffect(()=>{
		//n tot, n selezionati, totale, totale selezionati, iva, iva selezionati
		const nrighe=rows.length;
		const nsel=selectedRows.length;
		const totale=rows.reduce((acc, {totaleDocumento}) => parseFloat(totaleDocumento || 0) + acc, 0);
		const totaleIva=rows.reduce((acc, {ivaDocumento}) => parseFloat(ivaDocumento || 0) + acc, 0);
		const righeSelezionate=rows.filter(r=>selectedRows.includes(r.idScadenza));
		const totaleSel=righeSelezionate.reduce((acc, {totaleDocumento}) => parseFloat(totaleDocumento || 0) + acc, 0);
		const totaleIvaSel=righeSelezionate.reduce((acc, {ivaDocumento}) => parseFloat(ivaDocumento || 0) + acc, 0);
		setRiepilogo({totale,totaleIva,nsel,nrighe,totaleSel,totaleIvaSel});
	}, [rows, selectedRows]);
	
	return (
		<div>
			<Loader open={isLoaderOpen} />
			<Filtro filtra={filtra} />
			<div style={{height:'70vh'}}>
				<DataGridPro
					rows={rows}
					columns={columns}
					autoHeight={false}
					getRowId={r => r.idScadenza}
					onSelectionModelChange={selected => setSelectedRows(selected)}
					rowSelectionModel={selectedRows}
					isRowSelectable={(params) => params.row.descrizioneDocumento === ""}
					checkboxSelection={true}
				/>
			</div>
			<Grid container spacing={1} style={{marginTop:50}}>
				<Grid item xs={6} md={1}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.nrighe} InputLabelProps={{shrink:true}} label='Righe' /></Grid>
				<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.totale.toFixed(2)} InputLabelProps={{shrink:true}} label='Totale' /></Grid>
				<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.totaleIva.toFixed(2)} InputLabelProps={{shrink:true}} label='IVA totale' /></Grid>
				<Grid item xs={6} md={1}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.nsel} InputLabelProps={{shrink:true}} label='Righe selezionate' /></Grid>
				<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.totaleSel.toFixed(2)} InputLabelProps={{shrink:true}} label='Totale selezionate' /></Grid>
				<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.totaleIvaSel.toFixed(2)} InputLabelProps={{shrink:true}} label='IVA totale selezionate' /></Grid>
			</Grid>
			{/* Dialog con barra di progresso */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Creazione fatture in corso...</DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
					<CircularProgress />
				</DialogContent>
            </Dialog>
		</div>
	)
	
}